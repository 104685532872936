export const baseUrlApi = process?.env?.VUE_APP_STRDG_BASE_URL_API || ''

export const sysMsg_operationSuccess = 'Operation successfully completed'
export const sysMsg_operationError = 'An error occurred'
export const sysMsg_internalServerError = 'Internal server error'
export const sysMsg_noItemsSelected = 'No items selected'

export const flatpickrConfig = {
	// wrap: true,
	altFormat: "M j, Y",
	altInput: true,
	dateFormat: "Y-m-d",// "d M, Y",
	// parseDate: (datestr, format) => {
	// 	return flatpickr.formatDate(datestr, );
	// },
	// formatDate: (date, format, locale) => {
	// 	// locale can also be used
	// 	return moment(date).format(format);
	// }
}
export const flatpickrConfigYear = {
    dateFormat: "Y",
}
export const flatpickrConfigMonth = {
    dateFormat: "m",
}
export const flatpickrConfigHour = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
}

export const tablePerPageResults = 10

export const tablePerPageSelectAvailableValues = [
	{itemId: "10", itemLabel: "10"},
	{itemId: "25", itemLabel: "25"},
	{itemId: "50", itemLabel: "50"},
	{itemId: "100", itemLabel: "100"},
]

export const defaultYesNoSelectObjList = [
	{itemId: 1, itemLabel: "Yes"},
	{itemId: 0, itemLabel: "No"},
]

export const defaultYesNoSelect2ObjList = [
	{itemId: 'Y', itemLabel: "Yes"},
	{itemId: 'N', itemLabel: "No"},
]

export const defaultRatingSelectObjList = [
	{itemId: '1', itemLabel: "Very Poor"},
	{itemId: '2', itemLabel: "Poor"},
	{itemId: '3', itemLabel: "Average"},
	{itemId: '4', itemLabel: "Good"},
	{itemId: '5', itemLabel: "Excellent"},
]

export const defaultGenderObjList = [
	{itemId: 'M', itemLabel: "Male"},
	{itemId: 'F', itemLabel: "Female"},
	{itemId: 'O', itemLabel: "Other"},
]

export const languageObjList = [
	{itemId: "it", itemLabel: 'it'},
	{itemId: "en", itemLabel: 'en'},
	{itemId: "es", itemLabel: 'es'},
	{itemId: "de", itemLabel: 'de'},
	{itemId: "fr", itemLabel: 'fr'},
]

export const amountTypeObjList = [
	{itemId: 'V', itemLabel: "Amount"},
	{itemId: 'P', itemLabel: "Percentage"},
]

export const exportFileExtensionObjList = [
	{itemId: 'xlsx', itemLabel: 'Excel'},
	{itemId: 'csv', itemLabel: 'Csv'},
]

export const mimeToExtensionMappingObjList = [
	{extension: 'csv', mime: 'text/csv'},
	{extension: 'xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
]

export const serviceAreaObjList = [
	{ itemId: 'ABRUZZO', itemLabel: 'ABRUZZO'},
	{ itemId: 'AMALFI COAST', itemLabel: 'AMALFI COAST'},
	{ itemId: 'APULIA & MATERA', itemLabel: 'APULIA & MATERA'},
	{ itemId: 'CALABRIA', itemLabel: 'CALABRIA'},
	{ itemId: 'CAMPANIA', itemLabel: 'CAMPANIA'},
	{ itemId: 'CAPRI', itemLabel: 'CAPRI'},
	{ itemId: 'CINQUE TERRE', itemLabel: 'CINQUE TERRE'},
	{ itemId: 'COMO LAKE', itemLabel: 'COMO LAKE'},
	{ itemId: 'EMILIA ROMAGNA', itemLabel: 'EMILIA ROMAGNA'},
	{ itemId: 'F.V. GIULIA', itemLabel: 'F.V. GIULIA'},
	{ itemId: 'FLORENCE', itemLabel: 'FLORENCE'},
	{ itemId: 'FRANCE', itemLabel: 'FRANCE'},
	{ itemId: 'GENERIC', itemLabel: 'GENERIC'},
	{ itemId: 'ISCHIA', itemLabel: 'ISCHIA'},
	{ itemId: 'LAKE GARDA', itemLabel: 'LAKE GARDA'},
	{ itemId: 'LANGHE', itemLabel: 'LANGHE'},
	{ itemId: 'LIGURIA', itemLabel: 'LIGURIA'},
	{ itemId: 'LOMBADIA', itemLabel: 'LOMBADIA'},
	{ itemId: 'LOMBARDIA LAKES', itemLabel: 'LOMBARDIA LAKES'},
	{ itemId: 'MARCHE', itemLabel: 'MARCHE'},
	{ itemId: 'MILAN', itemLabel: 'MILAN'},
	{ itemId: 'MOLISE', itemLabel: 'MOLISE'},
	{ itemId: 'PIEDMONT', itemLabel: 'PIEDMONT'},
	{ itemId: 'PIEMONTE LAKES', itemLabel: 'PIEMONTE LAKES'},
	{ itemId: 'PORTOFINO', itemLabel: 'PORTOFINO'},
	{ itemId: 'ROME & LAZIO', itemLabel: 'ROME & LAZIO'},
	{ itemId: 'SARDINIA', itemLabel: 'SARDINIA'},
	{ itemId: 'SICILY', itemLabel: 'SICILY'},
	{ itemId: 'SORRENTO', itemLabel: 'SORRENTO'},
	{ itemId: 'TRENTINO ALTO ADIGE', itemLabel: 'TRENTINO ALTO ADIGE'},
	{ itemId: 'TURIN', itemLabel: 'TURIN'},
	{ itemId: 'TUSCANY', itemLabel: 'TUSCANY'},
	{ itemId: 'TUSCANY - MAREMMA', itemLabel: 'TUSCANY - MAREMMA'},
	{ itemId: 'TUSCANY- CHIANTI', itemLabel: 'TUSCANY- CHIANTI'},
	{ itemId: 'TUSCANY- ISOLA D\'ELBA', itemLabel: 'TUSCANY- ISOLA D\'ELBA'},
	{ itemId: 'TUSCANY- VAL D\'ORCIA', itemLabel: 'TUSCANY- VAL D\'ORCIA'},
	{ itemId: 'UMBRIA', itemLabel: 'UMBRIA'},
	{ itemId: 'VALLE D\'AOSTA', itemLabel: 'VALLE D\'AOSTA'},
	{ itemId: 'VENETO', itemLabel: 'VENETO'},
	{ itemId: 'VENETO - VALPOLICELLA', itemLabel: 'VENETO - VALPOLICELLA'},
	{ itemId: 'VENETO LAKES', itemLabel: 'VENETO LAKES'},
	{ itemId: 'VENICE ', itemLabel: 'VENICE '},
]

export const serviceTypeObjList = [
	// {itemId: 1, itemLabel: 'NCC'},
	// {itemId: 2, itemLabel: 'Guide'},
	// {itemId: 3, itemLabel: 'Venues'},
	// {itemId: 4, itemLabel: 'Activity'},
	// {itemId: 5, itemLabel: 'Boat'},
	// {itemId: 6, itemLabel: 'Equipment'},
	// {itemId: 7, itemLabel: 'Restaurant'},
	// {itemId: 8, itemLabel: 'Helicopter'},
	// {itemId: 9, itemLabel: 'Segway'},
	// {itemId: 10, itemLabel: 'Hotel'},
	// {itemId: 11, itemLabel: 'Other'},

	{
		itemId: 1,
		itemLabel: 'ACCOMODATION - HOTEL',
		itemAbbreviation: "ACCH",
	},
	{
		itemId: 2,
		itemLabel: 'ACCOMODATION - VILLAS&APARTMENT',
		itemAbbreviation: "ACCVP",
	},
	{
		itemId: 3,
		itemLabel: 'ACCOMODATION - OTHER',
		itemAbbreviation: "ACCOT",
	},
	{
		itemId: 4,
		itemLabel: 'BOAT',
		itemAbbreviation: "BOA",
	},
	{
		itemId: 5,
		itemLabel: 'EQUIPMENT',
		itemAbbreviation: "EQU",
	},
	{
		itemId: 6,
		itemLabel: 'EXPERIENCE',
		itemAbbreviation: "EXP",
	},
	{
		itemId: 7,
		itemLabel: 'GUIDE - TOUR LEADER',
		itemAbbreviation: "GDTL",
	},
	{
		itemId: 8,
		itemLabel: 'GUIDE - ASSISTANT',
		itemAbbreviation: "GDAS",
	},
	{
		itemId: 9,
		itemLabel: 'GUIDE - OFFICIAL GUIDE',
		itemAbbreviation: "GDOG",
	},
	{
		itemId: 10,
		itemLabel: 'HELICOPTER',
		itemAbbreviation: "HELI",
	},
	{
		itemId: 11,
		itemLabel: 'OTHER',
		itemAbbreviation: "OTH",
	},
	{
		itemId: 12,
		itemLabel: 'CONCIERGE SERVICE',
		itemAbbreviation: "CONC",
	},
	{
		itemId: 13,
		itemLabel: 'TICKETING - BOAT',
		itemAbbreviation: "TKTB",
	},
	{
		itemId: 14,
		itemLabel: 'TICKETING - FLIGHT',
		itemAbbreviation: "TKTF", 
	},
	{
		itemId: 15,
		itemLabel: 'TICKETING - TRAIN',
		itemAbbreviation: "TKTT",
	},
	{
		itemId: 16,
		itemLabel: 'TRANSPORTATION',
		itemAbbreviation: "TRAN",
	},
	{
		itemId: 17,
		itemLabel: 'VENUE',
		itemAbbreviation: "VENU",
	},
]

export const expertiseTypeObjList = [
	// {itemId: 1, itemLabel: 'Couples'},
	// {itemId: 2, itemLabel: 'Cultural'},
	// {itemId: 3, itemLabel: 'Families'},
	// {itemId: 4, itemLabel: 'Groups'},
	// {itemId: 5, itemLabel: 'Small Groups'},
	// {itemId: 6, itemLabel: 'VIP'},
	// {itemId: 7, itemLabel: 'Wow Effect'},

	{ itemId: '1', itemLabel: 'ARCHEO'},
	{ itemId: '2', itemLabel: 'ARCHITECTUR'},
	{ itemId: '3', itemLabel: 'ART&HISTORY'},
	{ itemId: '4', itemLabel: 'COUPLES'},
	{ itemId: '5', itemLabel: 'FAMILIES'},
	{ itemId: '6', itemLabel: 'FASHION&LIFESTYLE'},
	{ itemId: '7', itemLabel: 'FOOD&BEVERAGE'},
	{ itemId: '8', itemLabel: 'GROUPS'},
	{ itemId: '9', itemLabel: 'JEWISH'},
	{ itemId: '10', itemLabel: 'TEENAGERS'},
	{ itemId: '11', itemLabel: 'VIP'},	
]

export const countryCodeObjList = [
	{itemId:'ABW', itemLabel: 'Aruba'},
	{itemId:'AFG', itemLabel: 'Afghanistan'},
	{itemId:'AGO', itemLabel: 'Angola'},
	{itemId:'AIA', itemLabel: 'Anguilla'},
	{itemId:'ALA', itemLabel: 'Åland Islands'},
	{itemId:'ALB', itemLabel: 'Albania'},
	{itemId:'AND', itemLabel: 'Andorra'},
	{itemId:'ARE', itemLabel: 'United Arab Emirates'},
	{itemId:'ARG', itemLabel: 'Argentina'},
	{itemId:'ARM', itemLabel: 'Armenia'},
	{itemId:'ASM', itemLabel: 'American Samoa'},
	{itemId:'ATA', itemLabel: 'Antarctica'},
	{itemId:'ATF', itemLabel: 'French Southern Territories'},
	{itemId:'ATG', itemLabel: 'Antigua and Barbuda'},
	{itemId:'AUS', itemLabel: 'Australia'},
	{itemId:'AUT', itemLabel: 'Austria'},
	{itemId:'AZE', itemLabel: 'Azerbaijan'},
	{itemId:'BDI', itemLabel: 'Burundi'},
	{itemId:'BEL', itemLabel: 'Belgium'},
	{itemId:'BEN', itemLabel: 'Benin'},
	{itemId:'BES', itemLabel: 'Bonaire, Sint Eustatius and Saba'},
	{itemId:'BFA', itemLabel: 'Burkina Faso'},
	{itemId:'BGD', itemLabel: 'Bangladesh'},
	{itemId:'BGR', itemLabel: 'Bulgaria'},
	{itemId:'BHR', itemLabel: 'Bahrain'},
	{itemId:'BHS', itemLabel: 'Bahamas'},
	{itemId:'BIH', itemLabel: 'Bosnia and Herzegovina'},
	{itemId:'BLM', itemLabel: 'Saint Barthélemy'},
	{itemId:'BLR', itemLabel: 'Belarus'},
	{itemId:'BLZ', itemLabel: 'Belize'},
	{itemId:'BMU', itemLabel: 'Bermuda'},
	{itemId:'BOL', itemLabel: 'Bolivia, Plurinational State of'},
	{itemId:'BRA', itemLabel: 'Brazil'},
	{itemId:'BRB', itemLabel: 'Barbados'},
	{itemId:'BRN', itemLabel: 'Brunei Darussalam'},
	{itemId:'BTN', itemLabel: 'Bhutan'},
	{itemId:'BVT', itemLabel: 'Bouvet Island'},
	{itemId:'BWA', itemLabel: 'Botswana'},
	{itemId:'CAF', itemLabel: 'Central African Republic'},
	{itemId:'CAN', itemLabel: 'Canada'},
	{itemId:'CCK', itemLabel: 'Cocos (Keeling) Islands'},
	{itemId:'CHE', itemLabel: 'Switzerland'},
	{itemId:'CHL', itemLabel: 'Chile'},
	{itemId:'CHN', itemLabel: 'China'},
	{itemId:'CIV', itemLabel: 'Côte d\'Ivoire'},
	{itemId:'CMR', itemLabel: 'Cameroon'},
	{itemId:'COD', itemLabel: 'Congo, Democratic Republic of the'},
	{itemId:'COG', itemLabel: 'Congo'},
	{itemId:'COK', itemLabel: 'Cook Islands'},
	{itemId:'COL', itemLabel: 'Colombia'},
	{itemId:'COM', itemLabel: 'Comoros'},
	{itemId:'CPV', itemLabel: 'Cabo Verde'},
	{itemId:'CRI', itemLabel: 'Costa Rica'},
	{itemId:'CUB', itemLabel: 'Cuba'},
	{itemId:'CUW', itemLabel: 'Curaçao'},
	{itemId:'CXR', itemLabel: 'Christmas Island'},
	{itemId:'CYM', itemLabel: 'Cayman Islands'},
	{itemId:'CYP', itemLabel: 'Cyprus'},
	{itemId:'CZE', itemLabel: 'Czechia'},
	{itemId:'DEU', itemLabel: 'Germany'},
	{itemId:'DJI', itemLabel: 'Djibouti'},
	{itemId:'DMA', itemLabel: 'Dominica'},
	{itemId:'DNK', itemLabel: 'Denmark'},
	{itemId:'DOM', itemLabel: 'Dominican Republic'},
	{itemId:'DZA', itemLabel: 'Algeria'},
	{itemId:'ECU', itemLabel: 'Ecuador'},
	{itemId:'EGY', itemLabel: 'Egypt'},
	{itemId:'ERI', itemLabel: 'Eritrea'},
	{itemId:'ESH', itemLabel: 'Western Sahara'},
	{itemId:'ESP', itemLabel: 'Spain'},
	{itemId:'EST', itemLabel: 'Estonia'},
	{itemId:'ETH', itemLabel: 'Ethiopia'},
	{itemId:'FIN', itemLabel: 'Finland'},
	{itemId:'FJI', itemLabel: 'Fiji'},
	{itemId:'FLK', itemLabel: 'Falkland Islands (Malvinas)'},
	{itemId:'FRA', itemLabel: 'France'},
	{itemId:'FRO', itemLabel: 'Faroe Islands'},
	{itemId:'FSM', itemLabel: 'Micronesia, Federated States of'},
	{itemId:'GAB', itemLabel: 'Gabon'},
	{itemId:'GBR', itemLabel: 'United Kingdom of Great Britain and Northern Ireland'},
	{itemId:'GEO', itemLabel: 'Georgia'},
	{itemId:'GGY', itemLabel: 'Guernsey'},
	{itemId:'GHA', itemLabel: 'Ghana'},
	{itemId:'GIB', itemLabel: 'Gibraltar'},
	{itemId:'GIN', itemLabel: 'Guinea'},
	{itemId:'GLP', itemLabel: 'Guadeloupe'},
	{itemId:'GMB', itemLabel: 'Gambia'},
	{itemId:'GNB', itemLabel: 'Guinea-Bissau'},
	{itemId:'GNQ', itemLabel: 'Equatorial Guinea'},
	{itemId:'GRC', itemLabel: 'Greece'},
	{itemId:'GRD', itemLabel: 'Grenada'},
	{itemId:'GRL', itemLabel: 'Greenland'},
	{itemId:'GTM', itemLabel: 'Guatemala'},
	{itemId:'GUF', itemLabel: 'French Guiana'},
	{itemId:'GUM', itemLabel: 'Guam'},
	{itemId:'GUY', itemLabel: 'Guyana'},
	{itemId:'HKG', itemLabel: 'Hong Kong'},
	{itemId:'HMD', itemLabel: 'Heard Island and McDonald Islands'},
	{itemId:'HND', itemLabel: 'Honduras'},
	{itemId:'HRV', itemLabel: 'Croatia'},
	{itemId:'HTI', itemLabel: 'Haiti'},
	{itemId:'HUN', itemLabel: 'Hungary'},
	{itemId:'IDN', itemLabel: 'Indonesia'},
	{itemId:'IMN', itemLabel: 'Isle of Man'},
	{itemId:'IND', itemLabel: 'India'},
	{itemId:'IOT', itemLabel: 'British Indian Ocean Territory'},
	{itemId:'IRL', itemLabel: 'Ireland'},
	{itemId:'IRN', itemLabel: 'Iran, Islamic Republic of'},
	{itemId:'IRQ', itemLabel: 'Iraq'},
	{itemId:'ISL', itemLabel: 'Iceland'},
	{itemId:'ISR', itemLabel: 'Israel'},
	{itemId:'ITA', itemLabel: 'Italy'},
	{itemId:'JAM', itemLabel: 'Jamaica'},
	{itemId:'JEY', itemLabel: 'Jersey'},
	{itemId:'JOR', itemLabel: 'Jordan'},
	{itemId:'JPN', itemLabel: 'Japan'},
	{itemId:'KAZ', itemLabel: 'Kazakhstan'},
	{itemId:'KEN', itemLabel: 'Kenya'},
	{itemId:'KGZ', itemLabel: 'Kyrgyzstan'},
	{itemId:'KHM', itemLabel: 'Cambodia'},
	{itemId:'KIR', itemLabel: 'Kiribati'},
	{itemId:'KNA', itemLabel: 'Saint Kitts and Nevis'},
	{itemId:'KOR', itemLabel: 'Korea, Republic of'},
	{itemId:'KWT', itemLabel: 'Kuwait'},
	{itemId:'LAO', itemLabel: 'Lao People\'s Democratic Republic'},
	{itemId:'LBN', itemLabel: 'Lebanon'},
	{itemId:'LBR', itemLabel: 'Liberia'},
	{itemId:'LBY', itemLabel: 'Libya'},
	{itemId:'LCA', itemLabel: 'Saint Lucia'},
	{itemId:'LIE', itemLabel: 'Liechtenstein'},
	{itemId:'LKA', itemLabel: 'Sri Lanka'},
	{itemId:'LSO', itemLabel: 'Lesotho'},
	{itemId:'LTU', itemLabel: 'Lithuania'},
	{itemId:'LUX', itemLabel: 'Luxembourg'},
	{itemId:'LVA', itemLabel: 'Latvia'},
	{itemId:'MAC', itemLabel: 'Macao'},
	{itemId:'MAF', itemLabel: 'Saint Martin (French part)'},
	{itemId:'MAR', itemLabel: 'Morocco'},
	{itemId:'MCO', itemLabel: 'Monaco'},
	{itemId:'MDA', itemLabel: 'Moldova, Republic of'},
	{itemId:'MDG', itemLabel: 'Madagascar'},
	{itemId:'MDV', itemLabel: 'Maldives'},
	{itemId:'MEX', itemLabel: 'Mexico'},
	{itemId:'MHL', itemLabel: 'Marshall Islands'},
	{itemId:'MKD', itemLabel: 'North Macedonia'},
	{itemId:'MLI', itemLabel: 'Mali'},
	{itemId:'MLT', itemLabel: 'Malta'},
	{itemId:'MMR', itemLabel: 'Myanmar'},
	{itemId:'MNE', itemLabel: 'Montenegro'},
	{itemId:'MNG', itemLabel: 'Mongolia'},
	{itemId:'MNP', itemLabel: 'Northern Mariana Islands'},
	{itemId:'MOZ', itemLabel: 'Mozambique'},
	{itemId:'MRT', itemLabel: 'Mauritania'},
	{itemId:'MSR', itemLabel: 'Montserrat'},
	{itemId:'MTQ', itemLabel: 'Martinique'},
	{itemId:'MUS', itemLabel: 'Mauritius'},
	{itemId:'MWI', itemLabel: 'Malawi'},
	{itemId:'MYS', itemLabel: 'Malaysia'},
	{itemId:'MYT', itemLabel: 'Mayotte'},
	{itemId:'NAM', itemLabel: 'Namibia'},
	{itemId:'NCL', itemLabel: 'New Caledonia'},
	{itemId:'NER', itemLabel: 'Niger'},
	{itemId:'NFK', itemLabel: 'Norfolk Island'},
	{itemId:'NGA', itemLabel: 'Nigeria'},
	{itemId:'NIC', itemLabel: 'Nicaragua'},
	{itemId:'NIU', itemLabel: 'Niue'},
	{itemId:'NLD', itemLabel: 'Netherlands, Kingdom of the'},
	{itemId:'NOR', itemLabel: 'Norway'},
	{itemId:'NPL', itemLabel: 'Nepal'},
	{itemId:'NRU', itemLabel: 'Nauru'},
	{itemId:'NZL', itemLabel: 'New Zealand'},
	{itemId:'OMN', itemLabel: 'Oman'},
	{itemId:'PAK', itemLabel: 'Pakistan'},
	{itemId:'PAN', itemLabel: 'Panama'},
	{itemId:'PCN', itemLabel: 'Pitcairn'},
	{itemId:'PER', itemLabel: 'Peru'},
	{itemId:'PHL', itemLabel: 'Philippines'},
	{itemId:'PLW', itemLabel: 'Palau'},
	{itemId:'PNG', itemLabel: 'Papua New Guinea'},
	{itemId:'POL', itemLabel: 'Poland'},
	{itemId:'PRI', itemLabel: 'Puerto Rico'},
	{itemId:'PRK', itemLabel: 'Korea, Democratic People\'s Republic of Korea'},
	{itemId:'PRT', itemLabel: 'Portugal'},
	{itemId:'PRY', itemLabel: 'Paraguay'},
	{itemId:'PSE', itemLabel: 'Palestine, State of'},
	{itemId:'PYF', itemLabel: 'French Polynesia'},
	{itemId:'QAT', itemLabel: 'Qatar'},
	{itemId:'REU', itemLabel: 'Réunion'},
	{itemId:'ROU', itemLabel: 'Romania'},
	{itemId:'RUS', itemLabel: 'Russian Federation'},
	{itemId:'RWA', itemLabel: 'Rwanda'},
	{itemId:'SAU', itemLabel: 'Saudi Arabia'},
	{itemId:'SDN', itemLabel: 'Sudan'},
	{itemId:'SEN', itemLabel: 'Senegal'},
	{itemId:'SGP', itemLabel: 'Singapore'},
	{itemId:'SGS', itemLabel: 'South Georgia and the South Sandwich Islands'},
	{itemId:'SHN', itemLabel: 'Saint Helena, Ascension and Tristan da Cunha'},
	{itemId:'SJM', itemLabel: 'Svalbard and Jan Mayen'},
	{itemId:'SLB', itemLabel: 'Solomon Islands'},
	{itemId:'SLE', itemLabel: 'Sierra Leone'},
	{itemId:'SLV', itemLabel: 'El Salvador'},
	{itemId:'SMR', itemLabel: 'San Marino'},
	{itemId:'SOM', itemLabel: 'Somalia'},
	{itemId:'SPM', itemLabel: 'Saint Pierre and Miquelon'},
	{itemId:'SRB', itemLabel: 'Serbia'},
	{itemId:'SSD', itemLabel: 'South Sudan'},
	{itemId:'STP', itemLabel: 'Sao Tome and Principe'},
	{itemId:'SUR', itemLabel: 'Suriname'},
	{itemId:'SVK', itemLabel: 'Slovakia'},
	{itemId:'SVN', itemLabel: 'Slovenia'},
	{itemId:'SWE', itemLabel: 'Sweden'},
	{itemId:'SWZ', itemLabel: 'Eswatini'},
	{itemId:'SXM', itemLabel: 'Sint Maarten (Dutch part)'},
	{itemId:'SYC', itemLabel: 'Seychelles'},
	{itemId:'SYR', itemLabel: 'Syrian Arab Republic'},
	{itemId:'TCA', itemLabel: 'Turks and Caicos Islands'},
	{itemId:'TCD', itemLabel: 'Chad'},
	{itemId:'TGO', itemLabel: 'Togo'},
	{itemId:'THA', itemLabel: 'Thailand'},
	{itemId:'TJK', itemLabel: 'Tajikistan'},
	{itemId:'TKL', itemLabel: 'Tokelau'},
	{itemId:'TKM', itemLabel: 'Turkmenistan'},
	{itemId:'TLS', itemLabel: 'Timor-Leste'},
	{itemId:'TON', itemLabel: 'Tonga'},
	{itemId:'TTO', itemLabel: 'Trinidad and Tobago'},
	{itemId:'TUN', itemLabel: 'Tunisia'},
	{itemId:'TUR', itemLabel: 'Türkiye'},
	{itemId:'TUV', itemLabel: 'Tuvalu'},
	{itemId:'TWN', itemLabel: 'Taiwan, Province of China'},
	{itemId:'TZA', itemLabel: 'Tanzania, United Republic of'},
	{itemId:'UGA', itemLabel: 'Uganda'},
	{itemId:'UKR', itemLabel: 'Ukraine'},
	{itemId:'UMI', itemLabel: 'United States Minor Outlying Islands'},
	{itemId:'URY', itemLabel: 'Uruguay'},
	{itemId:'USA', itemLabel: 'United States of America'},
	{itemId:'UZB', itemLabel: 'Uzbekistan'},
	{itemId:'VAT', itemLabel: 'Holy See'},
	{itemId:'VCT', itemLabel: 'Saint Vincent and the Grenadines'},
	{itemId:'VEN', itemLabel: 'Venezuela, Bolivarian Republic of'},
	{itemId:'VGB', itemLabel: 'Virgin Islands (British)'},
	{itemId:'VIR', itemLabel: 'Virgin Islands (U.S.)'},
	{itemId:'VNM', itemLabel: 'Viet Nam'},
	{itemId:'VUT', itemLabel: 'Vanuatu'},
	{itemId:'WLF', itemLabel: 'Wallis and Futuna'},
	{itemId:'WSM', itemLabel: 'Samoa'},
	{itemId:'YEM', itemLabel: 'Yemen'},
	{itemId:'ZAF', itemLabel: 'South Africa'},
	{itemId:'ZMB', itemLabel: 'Zambia'},
	{itemId:'ZWE', itemLabel: 'Zimbabwe'},
]

export const timezoneObjList = [
	{itemId: 'UTC (Universal Coordinated Time)', itemLabel: 'UTC (Universal Coordinated Time)'},
	{itemId: 'GMT (Greenwich Mean Time)', itemLabel: 'GMT (Greenwich Mean Time)'},
	{itemId: 'ECT (European Central Time)', itemLabel: 'ECT (European Central Time)'},
	{itemId: 'EET (Eastern European Time)', itemLabel: 'EET (Eastern European Time)'},
	{itemId: 'ART (Egypt Standard Time Arabic)', itemLabel: 'ART (Egypt Standard Time Arabic)'},
	{itemId: 'EAT (Eastern African Time)', itemLabel: 'EAT (Eastern African Time)'},
	{itemId: 'MET (Middle East Time)', itemLabel: 'MET (Middle East Time)'},
	{itemId: 'NET (Near East Time)', itemLabel: 'NET (Near East Time)'},
	{itemId: 'PLT (Pakistan Lahore Time)', itemLabel: 'PLT (Pakistan Lahore Time)'},
	{itemId: 'IST (India Standard Time)', itemLabel: 'IST (India Standard Time)'},
	{itemId: 'BST (Bangladesh Standard Time)', itemLabel: 'BST (Bangladesh Standard Time)'},
	{itemId: 'VST (Vietnam Standard Time)', itemLabel: 'VST (Vietnam Standard Time)'},
	{itemId: 'CTT (China Taiwan Time)', itemLabel: 'CTT (China Taiwan Time)'},
	{itemId: 'JST (Japan Standard Time)', itemLabel: 'JST (Japan Standard Time)'},
	{itemId: 'ACT (Australia Central Time)', itemLabel: 'ACT (Australia Central Time)'},
	{itemId: 'AET (Australia Eastern Time)', itemLabel: 'AET (Australia Eastern Time)'},
	{itemId: 'SST (Solomon Standard Time)', itemLabel: 'SST (Solomon Standard Time)'},
	{itemId: 'NST (New Zealand Standard Time)', itemLabel: 'NST (New Zealand Standard Time)'},
	{itemId: 'MIT (Midway Islands Time)', itemLabel: 'MIT (Midway Islands Time)'},
	{itemId: 'HST (Hawaii Standard Time)', itemLabel: 'HST (Hawaii Standard Time)'},
	{itemId: 'AST (Alaska Standard Time)', itemLabel: 'AST (Alaska Standard Time)'},
	{itemId: 'PST (Pacific Standard Time)', itemLabel: 'PST (Pacific Standard Time)'},
	{itemId: 'PNT (Phoenix Standard Time)', itemLabel: 'PNT (Phoenix Standard Time)'},
	{itemId: 'MST (Mountain Standard Time)', itemLabel: 'MST (Mountain Standard Time)'},
	{itemId: 'CST (Central Standard Time)', itemLabel: 'CST (Central Standard Time)'},
	{itemId: 'EST (Eastern Standard Time)', itemLabel: 'EST (Eastern Standard Time)'},
	{itemId: 'IET (Indiana Eastern Standard Time)', itemLabel: 'IET (Indiana Eastern Standard Time)'},
	{itemId: 'PRT (Puerto Rico and US Virgin Islands Time)', itemLabel: 'PRT (Puerto Rico and US Virgin Islands Time)'},
	{itemId: 'CNT (Canada Newfoundland Time)', itemLabel: 'CNT (Canada Newfoundland Time)'},
	{itemId: 'AGT (Argentina Standard Time)', itemLabel: 'AGT (Argentina Standard Time)'},
	{itemId: 'BET (Brazil Eastern Time)', itemLabel: 'BET (Brazil Eastern Time)'},
	{itemId: 'CAT (Central African Time)', itemLabel: 'CAT (Central African Time)'},
]

export const supplierStatusObjList = [
	{ itemId: 'NEW', itemLabel: 'New'},
	{ itemId: 'PARTNER', itemLabel: 'Partner'},
	{ itemId: 'LOST', itemLabel: 'Lost'},
]

export const supplierPaymentRegulationObjList = [
	{ itemId: 'post_service_payment_invoice', itemLabel: 'Post Service Payment - Invoice'},
	{ itemId: 'prepayment_proforma', itemLabel: 'Prepayment - Proforma'},
	{ itemId: 'mixed_payment', itemLabel: 'Mixed Payment'},
]

export const supplierAmbassadorLevelObjList = [
	{ itemId: 'Bronze', itemLabel: 'Bronze'},
	{ itemId: 'Silver', itemLabel: 'Silver'},
	{ itemId: 'Gold', itemLabel: 'Gold'},
	{ itemId: 'Platinum', itemLabel: 'Platinum'},
]

export const supplierReferredByTypeObjList = [
	{itemId: "client", itemLabel: "Client"},
	{itemId: "agent", itemLabel: "Agent"},
	{itemId: "agency", itemLabel: "Agency"},
	{itemId: "supplier", itemLabel: "Supplier"},
	{itemId: "user", itemLabel: "User"},
	// {itemId: "supplier", itemLabel: "Supplier"},
]

export const supplierPaymentTypeObjList = [
	{itemId: 'prepagato', itemLabel: 'Prepaid'},
	{itemId: 'postpagato', itemLabel: 'Postpaid'},
]

export const agencySpecialtyObjList = [
	{itemId: 1, itemLabel: 'Leisure'},
	{itemId: 2, itemLabel: 'Corporate'},
	{itemId: 3, itemLabel: 'Destination Weddings'},
	{itemId: 4, itemLabel: 'Small group/Group Division'},
	{itemId: 5, itemLabel: 'Cruises'},
]

export const agencyTargetInterestObjList = [
	{itemId: 1, itemLabel: 'Romance travel and honeymoons'},
	{itemId: 2, itemLabel: 'Families'},
	{itemId: 3, itemLabel: 'Multi-generational'},
	{itemId: 4, itemLabel: 'Culture, art & history'},
	{itemId: 5, itemLabel: 'Food & wine'},
	{itemId: 6, itemLabel: 'Adventure/active'},
	{itemId: 7, itemLabel: 'Wellness'},
	{itemId: 8, itemLabel: 'Luxury lifestyle'},
	{itemId: 9, itemLabel: 'Jewish heritage'},
	{itemId: 10, itemLabel: 'LGBTQ'},
]




// WPPRef	/\
//			||
// Ver 1	||
// export const agentTargetInterestObjList = [
// 	{itemId: 1, itemLabel: 'Romance travel and honeymoons'},
// 	{itemId: 2, itemLabel: 'Families'},
// 	{itemId: 3, itemLabel: 'Multi-generational'},
// 	{itemId: 4, itemLabel: 'Culture, art & history'},
// 	{itemId: 5, itemLabel: 'Food & wine'},
// 	{itemId: 6, itemLabel: 'Adventure/active'},
// 	{itemId: 7, itemLabel: 'Wellness'},
// 	{itemId: 8, itemLabel: 'Luxury lifestyle'},
// 	{itemId: 9, itemLabel: 'Jewish heritage'},
// 	{itemId: 10, itemLabel: 'LGBTQ'},
// ]
// Ver 2
export const agentTargetInterestObjList = agencyTargetInterestObjList




export const agentSpecialtyObjList = [
	{itemId: 1, itemLabel: 'Leisure'},
	{itemId: 2, itemLabel: 'Corporate'},
	{itemId: 3, itemLabel: 'Destination Weddings'},
	{itemId: 4, itemLabel: 'Small group/Group Division'},
	{itemId: 5, itemLabel: 'Cruises'},
]

export const agencyStatusObjList = [
	{itemId: 1, itemLabel: 'New'},
	{itemId: 2, itemLabel: 'Active'},
	{itemId: 3, itemLabel: 'Partner'},
]

export const agentStatusObjList = [
	{itemId: 1, itemLabel: 'New'},
	{itemId: 2, itemLabel: 'Yes feedback'},
	{itemId: 3, itemLabel: 'No feedback'},
	{itemId: 4, itemLabel: 'Yes interest'},
	{itemId: 5, itemLabel: 'No interest'},
	{itemId: 6, itemLabel: 'In process'},
	{itemId: 7, itemLabel: 'False contact'},
	{itemId: 8, itemLabel: 'Lost'},
]

export const agentJobTitleObjList = [
	{itemId: 1, itemLabel: 'Owner/Founder'},
	{itemId: 2, itemLabel: 'Co-Owner'},
	{itemId: 3, itemLabel: 'Manager'},
	{itemId: 4, itemLabel: 'Advisor'},
	{itemId: 5, itemLabel: 'Advisor (home-based)'},
	{itemId: 6, itemLabel: 'Advisor (independent with host agency)'},
]

export const agentNetworkObjList = [
	{itemId: 1, itemLabel: 'Amex'},
	{itemId: 2, itemLabel: 'Ensemble'},
	{itemId: 3, itemLabel: 'Signature'},
	{itemId: 4, itemLabel: 'Virtuoso'},
	{itemId: 5, itemLabel: 'Serendipians by Traveller Made'},
	{itemId: 6, itemLabel: 'Internova'},
	{itemId: 7, itemLabel: 'Select'},
]

export const agentMainDestinationObjList = [
	{itemId: 1, itemLabel: 'North America'},
	{itemId: 2, itemLabel: 'South America'},
	{itemId: 3, itemLabel: 'Europe'},
	{itemId: 4, itemLabel: 'Africa'},
	{itemId: 5, itemLabel: 'Antarctica'},
	{itemId: 6, itemLabel: 'Asia'},
	{itemId: 7, itemLabel: 'Australia'},
]

export const agentRatesPreferenceObjList = [
	{itemId: 'N', itemLabel: 'Net'},
	{itemId: 'G', itemLabel: 'Gross'},
]

export const clientTravellerTypeObjList =  [
	{itemId: 'adults', itemLabel: 'Adults'},
	{itemId: 'kids', itemLabel: 'Kids'},
	{itemId: 'children', itemLabel: 'Children'},
]

export const clientReferredByTypeObjList = supplierReferredByTypeObjList

export const productTypeObjList = [
	{itemId: "ACT", itemLabel: "Activity"},
	{itemId: "HT", itemLabel: "Hotel"},
	{itemId: "AS", itemLabel: "Ancillary Service"},
]

export const productIdealMarginTypeObjList = [
	{itemId: 'amount', itemLabel: 'Amount'},
	{itemId: 'perc', itemLabel: 'Percentage'},
]

export const productCategoryTypeObjList = [
	{itemId: 'accomodation', itemLabel: 'Accomodation'},
	{itemId: 'activity', itemLabel: 'Activity'},
]

// WPPRef
// actually is a text field inside itinerary details form
//
// export const itineraryAgentRatePreferenceObjList = [
// 	{itemId: 'N', itemLabel: 'Net'},
// 	{itemId: 'G', itemLabel: 'Gross'},
// ]
// export const itineraryAgentRatePreferenceObjList =
// 	agentRatesPreferenceObjList

export const itineraryIntermediaryTypeObjList = [
	// {itemId: '0', itemLabel: 'Agency'},
	{itemId: '1', itemLabel: 'Agent'},
]

export const itineraryStatusObjList = [
	{itemId: 1, itemLabel: 'New request'},
	{itemId: 2, itemLabel: 'In progress'},
	{itemId: 3, itemLabel: 'Booked'},
	{itemId: 4, itemLabel: 'Lost'},
]

export const itinerarySpecialNotesCategoryObjList = [	
	{itemId: 'Allergies', itemLabel: 'Allergies'},
	{itemId: 'Disabilities', itemLabel: 'Disabilities'},
	{itemId: 'Preferences', itemLabel: 'Preferences'},
	{itemId: 'Other', itemLabel: 'Other'},
]

export const itineraryStaffTeamObjList = [
	{itemId: 'RES', itemLabel: 'Reservations/Sales'},
	{itemId: 'OPS', itemLabel: 'Operations'},
	{itemId: 'GR', itemLabel: 'Guest Relations'},
	{itemId: 'PROD', itemLabel: 'Products'},
]

export const itineraryPaxContactPreferenceTeamObjList = [
	{itemId: 'P', itemLabel: 'Phone'},
	{itemId: 'E', itemLabel: 'Email'},
]

export const itineraryMerchantObjList = [
	{itemId: 1, itemLabel: 'TTC'},
	{itemId: 2, itemLabel: 'TTI'},
	{itemId: 3, itemLabel: 'TTG'},
]

export const itineraryPaymentGatewayObjList = [
	{itemId: 1, itemLabel: 'Nexi'},
	{itemId: 2, itemLabel: 'FlyWire'},
]

export const itineraryPaymentTypeObjList = [
	{itemId: 'deposit', itemLabel: 'Deposit'},
	{itemId: 'full balance', itemLabel: 'Full balance'},
]

export const itineraryTransactionTypeObjList = [
	{itemId: 'IN', itemLabel: 'IN'},
	{itemId: 'OUT', itemLabel: 'OUT'},
]

export const itineraryTransactionPaymentTypeObjList = [
	{itemId: 'Wire transfer', itemLabel: 'Wire transfer'},
	{itemId: 'Paypal', itemLabel: 'Paypal'},
	{itemId: 'Cash', itemLabel: 'Cash'},
	{itemId: 'Money transfer', itemLabel: 'Money transfer'},
	{itemId: 'Credit Card', itemLabel: 'Credit Card'},
]

export const itineraryTransactionStatusObjList = [
	{itemId: 'new', itemLabel: 'New'},
	{itemId: 'paid', itemLabel: 'Paid'},
	{itemId: 'error', itemLabel: 'Error'},
]

export const itineraryPriorityLevelStatusObjList = [
	{itemId: 1, itemLabel: 'High'},
	{itemId: 2, itemLabel: 'Medium'},
	{itemId: 3, itemLabel: 'Low'},
]

export const merchantPaymentGatewayObjList = [
	{itemId: 1, itemLabel: 'Nexi'},
	{itemId: 2, itemLabel: 'FlyWire'},
]

export const reminderStatusObjList = [
	{itemId: 'to do', itemLabel: 'To do'},
	{itemId: 'in progress', itemLabel: 'In progess'},
	{itemId: 'completed', itemLabel: 'Completed'},
]
